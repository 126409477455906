<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อเพจกิจกรรม </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อเพจกิจกรรม" 
                rules="required">
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>URL พิเศษ </span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Url พิเศษ" 
                rules="special_charecter">
                <vs-input 
                  v-model="formData.custom_slug" 
                  class="w-full" 
                  name="slug" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <!-- <FormLayout>
            <FormLabel>
              <span>รายละเอียด</span>
            </FormLabel>
            <FormContent>
              <vs-textarea 
                v-model="formData.description" 
                class="w-full" />
            </FormContent>
          </FormLayout> -->
          <FormLayout class="flex flex-col">
            <div class="vx-col w-full md:w-6/12 py-2">
              <span>สร้างหน้ากิจกรรม</span>
              <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
            </div>
            <div class="vx-col w-full">
              <div class="content-editor">
                <Wyswyg                     
                  :folder="`${baseFolder}/content/${formData.folder_name}/wyswyg`"
                  v-model="formData.content" />
              </div>
            </div>
          </FormLayout>
          <!-- <FormLayout>
            <FormLabel>
              <span>วันที่เผยแพร่</span>
            </FormLabel>
            <FormContent>
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-1/2 md:px-2">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="วันที่เผยแพร่"
                  >
                    <datepicker
                      v-model="formData.published_date"
                      :minimum-view="'day'"
                      :maximum-view="'year'"
                      format="dd MMMM yyyy"
                      placeholder="วันที่เผยแพร่"
                      typeable
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">{{
                        errors[0]
                      }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </FormContent>
          </FormLayout> -->
        </vx-card>
        <vx-card 
          class="mb-base" 
          title="SEO">
          <FormLayout>
            <FormLabel>
              <span>Titlt SEO </span><span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">(กรอกได้ไม่เกิน 160 ตัวอักษร)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Titlt SEO"
                rules="required|max:160"
              >
                <vs-input 
                  v-model="formData.seo_title" 
                  class="w-full" 
                  name="seo_title"
                  :maxlength="160"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>H1</span>
              <br >
              <span class="text-warning text-sm">(กรอกได้ไม่เกิน 255 ตัวอักษร)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="H1" 
                rules="max:255">
                <vs-input 
                  v-model="formData.seo_h1" 
                  class="w-full" 
                  name="seo_h1" 
                  :maxlength="255"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Description </span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 300 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Description"
                rules="required|max:300"
              >
                <vs-textarea
                  v-model="formData.seo_description"
                  class="w-full"
                  name="meta_description"
                  rows="6"
                  :maxlength="300"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Keyword SEO </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Keywords"
                rules="required"
              >
                <vs-textarea
                  v-model="formData.seo_keywords"
                  class="w-full"
                  name="seo_keywords"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Thumbnail SEO </span>
              <br >
              <span class="text-warning text-sm">นามสกุล .jpg .jpeg</span>
              <br >
              <span class="text-warning text-sm">ขนาดรูปแนะนำ 600 x 338 px</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Thumbnail SEO"
                rules="lh_mimes:jpg,jpeg|lh_image_resolution:600,338"
              >
                <ImageUpload
                  :folder="`${baseFolder}/content/${formData.folder_name}/Seo Thumbnail`"
                  :has-delete-button="!isEmpty(formData.seo_image)"
                  v-model="formData.seo_image"
                  selection-name="seo_image"
                  crop="scale"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel/>
            <FormContent class="flex space-x-4">
              <vs-button 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
              <vs-button 
                v-if="formData.id !== null" 
                @click="preview()"
              >Preview</vs-button
              >
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import env from '@/env'
import ImageUpload from '@/components/upload/ImageUpload'
import useDatepicker from '@/use/useDatepicker'
import useLandingPageUtil from '@/use/useLandingPageUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import omit from 'lodash/omit'
import Wyswyg from '@/components/Wyswyg'

export default {
  name: 'LandingPageForm',
  components: {
    ImageUpload,
    Datepicker,
    ValidationObserver,
    Wyswyg
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError, ...notifyFn } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const { initialData, formData, categories, fetchByRouteId } = useLandingPageUtil(ctx)
    const crudForm = useCrud(ctx, 'landingPage')
    crudForm.routePrefix.value = 'landing-page'

    onMounted(() => {
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          return
        }
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        // add user created by
        if (!formData.created_by) {
          created_by = email
        }
        const updated_by = email

        $store
          .dispatch('landingPage/updateItem', { initialData,
            formData: {
              ...omit(formData, 'updated_at'),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'landing-page' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            if (
              error.message.split('unique constraint "content_slug_lang_key"').length > 1
            ) {
              notifyError({
                text:
                  'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ',
              })
            } else {
              notifyError({ text: JSON.stringify(error) })
            }
          })
      })
    }

    const submit = (ctx) => {
      formData.title = formData.title.trim()
      formData.slug = isEmpty(formData.custom_slug)
        ? formData.title.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-')
        : formData.custom_slug.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-')

      formData.custom_slug = formData.slug

      if (formData.id) {
        notifyFn.confirm({ 
          callback: () => { updateItem(ctx) }
        })
      } else {
        crudForm.addItem(ctx, omit(formData, 'updated_at'))
      }
    }

    const preview = () => {
      const lang = $store.state.locale.currentLang
      window.open(`${env.VUE_APP_WEBSITE_URL}/${lang}/landing-page/${formData.slug}`)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    return {
      ...crudForm,
      updateItem,
      formData,
      categories,
      submit,
      preview,
      useDatepicker: useDatepicker(),
      baseFolder,
      isEmpty
    }
  },
}
</script>
