var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อเพจกิจกรรม ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อเพจกิจกรรม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("URL พิเศษ ")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Url พิเศษ","rules":"special_charecter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"slug"},model:{value:(_vm.formData.custom_slug),callback:function ($$v) {_vm.$set(_vm.formData, "custom_slug", $$v)},expression:"formData.custom_slug"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{staticClass:"flex flex-col"},[_c('div',{staticClass:"vx-col w-full md:w-6/12 py-2"},[_c('span',[_vm._v("สร้างหน้ากิจกรรม")]),_c('p',{staticClass:"text-warning"},[_vm._v("**Support การ Upload Video ขนาดไม่เกิน 98MB")])]),_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"content-editor"},[_c('Wyswyg',{attrs:{"folder":(_vm.baseFolder + "/content/" + (_vm.formData.folder_name) + "/wyswyg")},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1)])])],1),_c('vx-card',{staticClass:"mb-base",attrs:{"title":"SEO"}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Titlt SEO ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("(กรอกได้ไม่เกิน 160 ตัวอักษร)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Titlt SEO","rules":"required|max:160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"seo_title","maxlength":160},model:{value:(_vm.formData.seo_title),callback:function ($$v) {_vm.$set(_vm.formData, "seo_title", $$v)},expression:"formData.seo_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("H1")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("(กรอกได้ไม่เกิน 255 ตัวอักษร)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"H1","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"seo_h1","maxlength":255},model:{value:(_vm.formData.seo_h1),callback:function ($$v) {_vm.$set(_vm.formData, "seo_h1", $$v)},expression:"formData.seo_h1"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Description ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 300 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Description","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"meta_description","rows":"6","maxlength":300},model:{value:(_vm.formData.seo_description),callback:function ($$v) {_vm.$set(_vm.formData, "seo_description", $$v)},expression:"formData.seo_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Keyword SEO ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Keywords","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"seo_keywords"},model:{value:(_vm.formData.seo_keywords),callback:function ($$v) {_vm.$set(_vm.formData, "seo_keywords", $$v)},expression:"formData.seo_keywords"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Thumbnail SEO ")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("นามสกุล .jpg .jpeg")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("ขนาดรูปแนะนำ 600 x 338 px")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Thumbnail SEO","rules":"lh_mimes:jpg,jpeg|lh_image_resolution:600,338"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/content/" + (_vm.formData.folder_name) + "/Seo Thumbnail"),"has-delete-button":!_vm.isEmpty(_vm.formData.seo_image),"selection-name":"seo_image","crop":"scale"},model:{value:(_vm.formData.seo_image),callback:function ($$v) {_vm.$set(_vm.formData, "seo_image", $$v)},expression:"formData.seo_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',{staticClass:"flex space-x-4"},[_c('vs-button',{on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("\n              "+_vm._s(_vm.formData.id ? 'Update' : 'Submit')+"\n            ")]),(_vm.formData.id !== null)?_c('vs-button',{on:{"click":function($event){return _vm.preview()}}},[_vm._v("Preview")]):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }